<template>
  <v-form class="py-4" ref="formSale">
    <v-row>
      <v-col
        v-if="!user"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="pb-4 pt-1 d-flex flex-column align-items-center justify-content-center"
      >
        <v-btn
          color="tertiary readable"
          depressed
          dark
          @click="
            $store.dispatch('changeAuthMenu', !$store.state.auth.authMenu);
            $store.dispatch('changeTabMenu', 'tab-login');
          "
          :class="{ [roundedFieldclass]: roundedFieldclass }"
          >{{ labels.buttonLogin[lang] }}</v-btn
        >
        <p v-if="!modoInvitado" class="text-center mt-2">
          <small class="error--text">{{ loginAdvertencyText[lang] }}</small>
        </p>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
        <h5 class="c-tertiary readable">{{ labels.general[lang] }}</h5>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
        <v-text-field
          :label="labels.general.email[lang]"
          :rounded="rounded"
          class="readable"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="ruleEmail"
          :readonly="user ? true : false"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          autocomplete="off"
          :hint="labels.general.hintEmail[lang]"
          persistent-hint
          v-model="payload.email"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        :lg="validateAdquiriente == false ? '4' : '12'"
        :md="validateAdquiriente == false ? '4' : '12'"
        sm="12"
        class="pb-1 pt-0"
      >
        <v-radio-group
          v-model="payload.documentType"
          row
          @change="clearDataAdquiriente"
          :disabled="validateAdquiriente == true ? foundDataAdquiriente : false"
        >
          <v-radio label="DNI" class="readable" :value="1"></v-radio>
          <v-radio label="C/E" class="readable" :value="2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        :lg="validateAdquiriente == false ? '8' : '12'"
        :md="validateAdquiriente == false ? '8' : '12'"
        sm="12"
        class="pb-2 pt-0 d-flex"
        :style="{ gap: '10px' }"
      >
        <v-text-field
          :label="labels.general.document[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :rules="visibilityRuleDNI ? ruleDocument : []"
          :disabled="disabled"
          :readonly="foundDataAdquiriente"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :hint="
            validateAdquiriente == true ? labels.general.documentHint[lang] : ''
          "
          persistent-hint
          :append-icon="foundDataAdquiriente ? 'highlight_off' : ''"
          @click:append="clearDataAdquiriente"
          :autocomplete="autocomplete"
          v-mask="payload.documentType == 1 ? '########' : '############'"
          v-model="payload.document"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-text-field>
        <v-btn
          v-if="validateAdquiriente"
          @click="getDataAdquiriente"
          class="btn-validate-ruc readable"
          dark
          large
          color="tertiary"
          depressed
          :loading="loadingAdquiriente"
          :disabled="loadingAdquiriente || foundDataAdquiriente"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
          {{ lang == "es" ? "Buscar" : "Search" }}
        </v-btn>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
        <v-text-field
          :label="labels.general.names[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :readonly="validateAdquiriente == true ? true : false"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          v-model="payload.names"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
        <v-text-field
          :label="labels.general.lastNames[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :readonly="validateAdquiriente == true ? true : false"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          v-model="payload.lastnames"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
        <h5 class="c-tertiary">{{ labels.location[lang] }}</h5>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
        <v-autocomplete
          :label="labels.location.department[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="departments"
          item-text="DESCRIPCION"
          item-value="CODIGO"
          @change="changeDepartment"
          v-model="payload.department"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
        <v-autocomplete
          :label="labels.location.province[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="$store.getters.getProvinces(payload.department)"
          item-text="DESCRIPCION"
          item-value="CODIGO"
          @change="changeProvince"
          v-model="payload.province"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
        <v-autocomplete
          :label="labels.location.district[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="$store.getters.getDistricts(payload.province)"
          item-text="DESCRIPCION"
          item-value="CODIGO"
          v-model="payload.district"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <!-- Acompaniantes -->
    <v-row v-if="permitFree && currentTotal > 1">
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-0 pt-0">
        <h5 class="c-tertiary readable">{{ labels.escorts[lang] }}</h5>
        <p class="mb-0 readable">
          <small
            >({{ totalRemaining }} {{ labels.escorts.remaining[lang] }})</small
          >
        </p>
      </v-col>
      <v-col cols="12" lg="12" sm="12" class="pt-1 pb-0">
        <v-radio-group v-model="escort.documentType" row>
          <v-radio label="DNI" class="readable" value="1"></v-radio>
          <v-radio label="C/E" class="readable" value="2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" class="pb-0 pt-0 d-flex" :style="{ gap: '10px' }">
        <v-text-field
          :label="labels.escorts.document[lang]"
          :rounded="rounded"
          :color="color"
          class="readable"
          :dark="dark"
          :background-color="bgColor"
          :disabled="disabled"
          hide-details
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          clearable
          v-mask="'############'"
          v-model="escort.document"
          :solo="solo"
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
        </v-text-field>
        <v-btn
          @click="addEscort"
          class="btn-validate-ruc readable"
          dark
          large
          color="tertiary"
          depressed
          :class="{ [roundedFieldclass]: roundedFieldclass }"
        >
          {{ lang == "es" ? "Agregar" : "Add" }}
        </v-btn>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-chip
          v-for="(item, idx) in payload.escorts"
          :key="item.DOCUMENTO + idx"
          color="tertiary"
          dark
          class="ma-2 readable"
          close
          @click:close="removeEscort(item.DOCUMENTO)"
        >
          {{ item.DOCUMENTO }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <!-- Facturation -->
      <v-col
        v-if="visibilityFacturation && permitirFactura == 1"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="pb-0 pt-0"
      >
        <h5 class="c-tertiary readable">{{ labels.facturation[lang] }}</h5>
      </v-col>
      <v-col
        v-if="visibilityFacturation && permitirFactura == 1"
        cols="12"
        lg="4"
        md="6"
        sm="8"
        class="pt-0 pb-0"
      >
        <v-checkbox
          v-model="payload.openFacturation"
          @change="handleFacturationChange"
          :label="labels.facturation.checkbox[lang]"
          class="readable"
        ></v-checkbox>
      </v-col>
      <template v-if="payload.openFacturation && visibilityFacturation && permitirFactura == 1">
        <v-col cols="12" lg="12" md="12" sm="12" class="pb-2 pt-0 d-flex" :style="{ gap: '10px' }">
          <v-text-field
            :label="labels.facturation.ruc[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :disabled="disabled"
            :readonly="foundDataFacturation"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :hint="labels.facturation.rucHint[lang]"
            persistent-hint
            :append-icon="foundDataFacturation ? 'highlight_off' : ''"
            @click:append="clearDataFacturation"
            :autocomplete="autocomplete"
            v-mask="'###########'"
            v-model="payload.facturation.ruc"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
          <v-btn
            @click="getDataFacturation"
            class="btn-validate-ruc readable"
            dark
            large
            color="tertiary"
            depressed
            :loading="loadingFacturation"
            :disabled="loadingFacturation || foundDataFacturation"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
            {{ lang == "es" ? "Buscar" : "Search" }}
          </v-btn>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-text-field
            :label="labels.facturation.rSocial[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :readonly="facturationDisabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.facturation.rSocial"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-text-field
            :label="labels.facturation.direction[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :readonly="facturationDisabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.facturation.direction"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-autocomplete
            :label="labels.facturation.department[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :readonly="facturationDisabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            :items="departments"
            item-text="DESCRIPCION"
            item-value="DEPARTAMENTO"
            v-model="payload.facturation.department"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-autocomplete
            :label="labels.facturation.province[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :readonly="facturationDisabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            :items="$store.getters.getProvinces(payload.facturation.department)"
            item-text="DESCRIPCION"
            item-value="PROVINCIA"
            v-model="payload.facturation.province"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" class="pb-1 pt-0">
          <v-autocomplete
            :label="labels.facturation.district[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :readonly="facturationDisabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            :items="
              $store.getters.getDistricts(
                `${payload.facturation.department}${payload.facturation.province}`
              )
            "
            item-text="DESCRIPCION"
            item-value="DISTRITO"
            v-model="payload.facturation.district"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-autocomplete>
        </v-col>
      </template>

      <v-col
        v-if="paymentList && paymentList.length > 1"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="pb-0 pt-3"
      >
        <h5 class="c-tertiary readable">{{ labels.payment[lang] }}</h5>
      </v-col>

      <v-col
        v-if="paymentList && paymentList.length > 1"
        cols="12"
        lg="6"
        md="6"
        sm="12"
        class="pb-1 pt-1"
      >
        <v-radio-group
          v-if="paymentList && paymentList.length > 0"
          v-model="payload.paymentType"
        >
          <v-radio
            v-for="(item, idx) in paymentList"
            :key="item.IDMODALIDADPAGO + 157896 + idx"
            :value="item.IDMODALIDADPAGO"
          >
            <template v-slot:label>
              <div class="d-flex align-items-center">
                <img :src="getSrcImg(item.URLLOGO)" width="50" alt="" />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <span v-else class="text-medium readable"
          >No se encontraron medios de pagos disponibles</span
        >
      </v-col>

      <v-col cols="12" lg="9" md="9" sm="12" class="pb-1 pt-1">
        <div class="">
          <v-checkbox
            v-model="payload.terms"
            hide-details=""
            class="readable"
            :label="labels.terms[lang]"
          >
          </v-checkbox>
          <button
            type="button"
            @click="dialog = true"
            class="c-tertiary fw-600 text-medium readable"
            text
            style="margin-left: 32px"
          >
            {{ labels.termsButton[lang] }}
          </button>
        </div>
      </v-col>

      <Dialog
        :dialog="dialog"
        :title="titleDialog[lang]"
        @closeDialog="dialog = false"
        width="700"
      >
        <template v-slot:content-dialog>
          <div v-html="terms" class="readable"></div>
        </template>
      </Dialog>
    </v-row>
  </v-form>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import Dialog from "@/components/Dialog";
import { imgService } from "@/libs/media.js";
import { emailValidator } from "@/libs/validators.js";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      ...baseConfig,
      ruleDocument: [this.validateDocument],
      ruleEmail: [this.validateEmail],
      required: [this.validateRequired],
      loginAdvertencyText: {
        en: "Login is required for this purchase.",
        es: "El inicio de sesión es requerido para esta compra."
      },
      titleDialog: {
        en: "Terms and Conditions",
        es: "Términos y condiciones",
      },
      invalidEmail: {
        en: "Wrong email",
        es: "Correo incorrecto",
      },
      invalidDocument: {
        en: "Invalid Document",
        es: "Documento inválido",
      },
      requiredLabel: {
        en: "Required field",
        es: "Campo requerido",
      },
      labels: {
        title: {
          en: "PURCHASE INFORMATION",
          es: "Información de compra",
        },
        buttonLogin: {
          en: "Identify yourself",
          es: "Identifícate",
        },
        general: {
          en: "GENERAL DATA",
          es: "DATOS GENERALES",
          email: {
            en: "E-mail",
            es: "Correo electrónico",
          },
          hintEmail: {
            en:
              "Please, verify that your email is correctly written before continuing.",
            es:
              "Por favor, verifique que su correo esté correctamente escrito antes de continuar",
          },
          document: {
            en: "Document number",
            es: "Número Documento",
          },
          documentHint: {
            en:
              "Enter your Document number and click on Search to auto-complete the required fields",
            es:
              "Ingrese su número de Documento y presione en Buscar para autocompletar los campos requeridos",
          },
          names: {
            en: "Name",
            es: "Nombre",
          },
          lastNames: {
            en: "Last name",
            es: "Apellidos",
          },
        },
        location: {
          en: "LOCATION DATA",
          es: "DATOS DE UBICACIÓN",
          department: {
            en: "Department",
            es: "Departamento",
          },
          province: {
            en: "Province",
            es: "Provincia",
          },
          district: {
            en: "District",
            es: "Distrito",
          },
        },
        escorts: {
          en: "IDENTIFICATION OF COMPANIONS",
          es: "IDENTIFICACIÓN DE ACOMPAÑANTES",
          remaining: {
            en: "companions to register",
            es: "acompañantes por registrar",
          },
          document: {
            en: "Document number",
            es: "Número de documento",
          },
        },
        facturation: {
          en: "BILLING",
          es: "FACTURACIÓN",
          checkbox: {
            en: "Request invoice",
            es: "Solicitar factura",
          },
          rucHint: {
            en:
              "Enter your RUC number and click on Search to auto-complete the required billing fields",
            es:
              "Ingrese su número de RUC y presione en Buscar para autocompletar los campos de facturación requeridos",
          },
          ruc: {
            en: "RUC Number",
            es: "Número de RUC",
          },
          rSocial: {
            en: "Business name",
            es: "Razón social",
          },
          direction: {
            en: "Direction",
            es: "Dirección",
          },
          department: {
            en: "Department",
            es: "Departamento",
          },
          province: {
            en: "Province",
            es: "Provincia",
          },
          district: {
            en: "District",
            es: "Distrito",
          },
        },
        termsButton: {
          en: "See terms and conditions",
          es: "Ver términos y condiciones",
        },
        terms: {
          en: "I accept the terms and conditions",
          es: "Acepto los términos y condiciones",
        },
        payment: {
          en: "PAYMENT METHOD",
          es: "MEDIO DE PAGO",
        },
      },
      autocomplete: "off",
      payload: {
        // General
        email: "",
        documentType: "1",
        document: "",
        names: "",
        lastnames: "",
        validate: false,
        // scorts
        escorts: [],
        // Location
        department: "15",
        province: "1501",
        district: "",
        // Facturation
        openFacturation: false,
        facturation: {},
        voucherType: "03",
        terms: false,
        paymentType: "1",
      },
      escort: {
        documentType: "1",
        document: "",
      },
      loadingFacturation: false,
      foundDataFacturation: false,
      loadingAdquiriente: false,
      foundDataAdquiriente: false,
      dialog: false,
      facturationDisabled: true,
    };
  },
  watch: {
    "payload.email"(val) {
      this.payload.email = String(val)
        .replace(/ /g, "")
        .trim()
        .toLowerCase();
    },
    "payload.names"(val) {
      this.payload.names = String(val).toUpperCase();
    },
    "payload.lastnames"(val) {
      this.payload.lastnames = String(val).toUpperCase();
    },
  },
  computed: {
    ...mapGetters({
      validateAdquiriente: "getValidateAdquiriente",
      lang: "getLanguage",
      departments: "getDepartments",
      terms: "getTermsAndConditions",
      paymentList: "getPaymentList",
      total: "getArticlesTotal",
      finalPayload: "getFinalPayload",
      permitFree: "validateFree",
      totalTickets: "getTotalTickets",
      haveAditionalProccess: "haveAditionalProccess",
      totalAforo: "getTotalPeople",
      currentTotal: "getCurrentTotal",
      permitirFactura: "permiteSolicitarFactura",
      user: "getSessionUser",
      modoInvitado: "permiteComprarModoInvitado"
    }),
    visibilityRuleDNI() {
      if (!this.finalPayload.category) return false;
      return this.finalPayload.category.PERMITIRGRATUITAS == 1 ||
        this.total > 700
        ? true
        : false;
    },
    visibilityFacturation() {
      return (
        this.total > 0 &&
        this.finalPayload.category &&
        this.finalPayload.category.PERMITIRGRATUITAS == 0
      );
    },
    totalRemaining() {
      //if(this.haveAditionalProccess) return this.totalAforo - 1 - this.payload.escorts.length;
      return this.currentTotal - 1 - this.payload.escorts.length;
    },
    ...mapState({
      user: function(state) {
        if (!state.auth.user) {
          this.payload.email = "";
          this.payload.documentType = "1";
          this.payload.document = "";
          this.payload.names = "";
          this.payload.lastnames = "";
          this.payload.validate = false;
          this.foundDataAdquiriente = false;

          return null;
        }
        const user = { ...state.auth.user };
        user.validate = Boolean(user.TIPODOCUMENTO && user.NUMERODOCUMENTO);
        this.payload.email = user.CORREO;
        if (this.foundDataAdquiriente == false) {
          this.payload.documentType = user.TIPODOCUMENTO;
          this.payload.document = user.NUMERODOCUMENTO;
          this.payload.names = user.NOMBRES;
          this.payload.lastnames = user.APELLIDOS;
        }

        return state.auth.user;
      },
    }),
  },
  methods: {
    handleFacturationChange(ev) {
      if (!ev) {
        this.clearDataFacturation();
        this.payload.voucherType = "03";
      } else {
        this.payload.voucherType = "01";
      }
    },
    clearDataFacturation() {
      this.payload.facturation = Object.assign({}, {});
      this.foundDataFacturation = false;
    },
    clearDataAdquiriente() {
      this.payload.validate = false;
      this.payload.document = "";
      this.payload.names = "";
      this.payload.lastnames = "";
      this.foundDataAdquiriente = false;
    },
    validateRequired(v) {
      if (!v) return this.requiredLabel[this.lang];
      return true;
    },
    validateEmail(v) {
      return emailValidator(v) || this.invalidEmail[this.lang];
    },
    validateDocument(v) {
      if (v && this.payload.documentType == 1 && v.length == 8) return true;
      if (v && this.payload.documentType == 2 && v.length >= 8) return true;
      return this.invalidDocument[this.lang];
    },
    changeDepartment() {
      this.payload.province = "";
    },
    changeProvince() {
      this.payload.district = "";
    },
    getSrcImg(file) {
      return imgService(file);
    },
    addEscort() {
      const documentNumber = this.escort.document;
      const type = this.escort.documentType;

      if (this.totalRemaining == 0) {
        this.$store.dispatch("generateSnackbarError", {
          en: "Companion list full",
          es: "Lista de acompañantes llena",
        });
        return;
      }

      if (!documentNumber) {
        this.$store.dispatch("generateSnackbarError", {
          en: "Enter the document number to add to the list of companions",
          es:
            "Ingrese el número de documento que será añadido a la lista de acompañantes",
        });
        return;
      }

      if (type == 1 && documentNumber.length != 8) {
        this.$store.dispatch("generateSnackbarError", {
          en: "The DNI format is invalid",
          es: "El formato de DNI es inválido",
        });
        return;
      }
      if (type == 2 && documentNumber.length < 8) {
        this.$store.dispatch("generateSnackbarError", {
          en: "The C/E format is invalid",
          es: "El formato de Carnet de Extranjería es inválido",
        });
        return;
      }

      if (documentNumber == this.payload.document) {
        this.$store.dispatch("generateSnackbarError", {
          en:
            "The document number is equal to the document number associated with the account",
          es:
            "El documento es igual al número de documento asociado a la cuenta",
        });
        return;
      }

      if (
        this.payload.escorts.findIndex((s) => s.DOCUMENTO == documentNumber) >
        -1
      ) {
        this.$store.dispatch("generateSnackbarError", {
          en: "The entered document is already registered",
          es: "El documento ya se encuentra registrado",
        });
        return;
      }

      this.payload.escorts.push({
        DOCUMENTO: documentNumber,
        TIPO_DOCUMENTO: type,
      });
    },
    removeEscort(document) {
      const idx = this.payload.escorts.findIndex(
        (e) => e.DOCUMENTO == document
      );
      if (idx > -1) {
        this.payload.escorts.splice(idx, 1);
      }
    },
    async validatePreSaveFree() {
      if (
        this.payload.escorts.findIndex(
          (s) => s.DOCUMENTO == this.payload.document
        ) > -1
      ) {
        this.$store.dispatch("generateSnackbarError", {
          en:
            "The document of a companion cannot be the same as the document number associated with the account",
          es:
            "El documento de un acompañante no puede ser igual al número de documento asociado a la cuenta",
        });
        return false;
      }
      if (this.payload.escorts.length + 1 != this.currentTotal) {
        this.$store.dispatch("generateSnackbarError", {
          en: `Complete the list of companions`,
          es: `Complete la lista de acompañantes`,
        });
        return false;
      }
      const res = await this.$store.dispatch("validateEmailOrDocument", {
        document: this.payload.document,
        email: this.payload.email,
        documentsList: this.payload.escorts,
      });
      if (!res) return false;

      return true;
    },
    async getDataAdquiriente() {
      // validate documento
      let error = false;
      if (this.payload.documentType == 1) {
        if (!this.payload.document || this.payload.document.length != 8) {
          error = true;
        }
      }
      if (this.payload.documentType == 2) {
        if (!this.payload.document || this.payload.document.length < 8) {
          error = true;
        }
      }

      if (error == true) {
        // error
        this.$store.dispatch("generateSnackbarError", {
          en: `Enter a valid document number to search`,
          es: `Escriba un número de documento válido para buscar`,
        });
        return;
      }
      this.loadingAdquiriente = true;
      const res = await this.$store.dispatch("getDocumentIdentityData", {
        DOCUMENTO: this.payload.document,
        TIPO: this.payload.documentType,
      });
      this.loadingAdquiriente = false;
      if (!res) {
        return;
      }
      if (res.NOMBRES == "" || res.APELLIDOS == "") {
        // error
        if (this.payload.documentType == 1) {
          this.$store.dispatch("generateSnackbarError", {
            en: `RENIEC identity validation services are not working, please try again later.`,
            es: `Los servicios de validación de identidad de RENIEC no se encuentran operativos, por favor intente mas tarde.`,
          });
        }
        if (this.payload.documentType == 2) {
          this.$store.dispatch("generateSnackbarError", {
            en: `MIGRATION identity validation services are not working, please try again later.`,
            es: `Los servicios de validación de identidad de MIGRACIONES no se encuentran operativos, por favor intente mas tarde.`,
          });
        }
        return;
      }

      this.payload.validate = true;
      this.payload.document = res.DOCUMENTO;
      this.payload.names = res.NOMBRES;
      this.payload.lastnames = res.APELLIDOS;

      this.foundDataAdquiriente = true;
    },
    async getDataFacturation() {
      // validate ruc
      if (
        !this.payload.facturation.ruc ||
        this.payload.facturation.ruc.length < 11
      ) {
        // error
        this.$store.dispatch("generateSnackbarError", {
          en: `Enter a valid ruc number to search`,
          es: `Escriba un número de ruc válido para buscar`,
        });
        return;
      }
      this.loadingFacturation = true;
      const res = await this.$store.dispatch(
        "getDocumentIdentityData",
        {
          DOCUMENTO: this.payload.facturation.ruc,
          TIPO: 'RUC',
        }
      );
      this.loadingFacturation = false;
      if (!res) {
        // error

        return;
      }

      this.payload.facturation = {
        ruc: this.payload.facturation.ruc,
        rSocial: res.RAZONSOCIAL,
        direction: res.DIRECCION,
        department: res.DEPARTAMENTO,
        province: res.PROVINCIA,
        district: res.DISTRITO,
        ubigee: res.UBIGEO,
      };
      this.foundDataFacturation = true;
    },
    openModalTerms() {},
  },
};
</script>

<style>
.btn-validate-ruc {
  height: 50px !important;
  border-radius: 0px 4px 4px 0 !important;
}
</style>
